import { Audience, Profile, ValidatedBatchResult } from "../models";
import isEqual from "lodash.isequal";
import { ViewModel } from "./view-model.viewmodel";
import { TopicsViewModel } from "./topics.viewmodel";
import { Platform } from "../models/platform.model";
import { CampaignStatusEnum } from "../enums";

/**
 * Represents a profile in RADR
 *
 * @export
 * @class ProfileViewModel
 */
export class ProfileViewModel extends Profile implements ViewModel {
  public isDirty: boolean = false;
  private fieldsToMarkAsDirty: string[] = [
    "definition",
    "priority",
    "name",
    "description",
    "exportKey",
    "controlPercentage",
    "capFixed",
    "capPercentage",
    "segmentId",
    "segmentName",
    "simplifiCategory",
    "simplifiSubcategory",
    "simplifiAttribute",
    "targetAudience",
    "controlAudience"
  ];

  constructor(profile: any = {}) {
    super();
    Object.assign(this, profile);

    this.isDirty = false;

    return new Proxy(this, {
      set: (target: any, property: string, value: any): boolean => {
        if (this.fieldsToMarkAsDirty.indexOf(property) !== -1 && !isEqual(target[property], value)) {
          this.isDirty = true;
        }
        target[property] = value;
        return true;
      }
    });
  }

  get canEdit(): boolean {
    return true;
  }

  public cappedControlCount: number;
  public cappedTestCount: number;
  public validatedBatchValues: { [key: string]: ValidatedBatchResult };
  public platforms: Platform[];
  public profileType: number;
  public lookBackType: string;
  public dynamicLookBackPeriodType: string;
  public dynamicLookBackPeriod: number;
  public lookBackStartDate: string;
  public lookBackEndDate: string;
  public dwellTimeMinutes: number;
  public status: CampaignStatusEnum;
  public topics?: TopicsViewModel[] = [];

  public setAsDirty(): void {
    this.isDirty = true;
  }

  public get isDefault(): boolean {
    return this.priority === 0;
  }

  public get hasMinimumQualifiers(): boolean {
    return this.definition?.rules?.length > 0;
  }

  public get containsRootProfiles(): boolean {
    return JSON.stringify(this.definition).indexOf(`"field":"p`) !== -1;
  }

  public get profileQualifierId(): string {
    return `p${this.id}`;
  }

  public get batchIds(): number[] {
    return Array.from(this.getBatchIdsFromRuleSet(this.definition.rules).values());
  }

  private getBatchIdsFromRuleSet(rules: any[]): Set<number> {
    const batchIds: Set<number> = new Set();

    rules.forEach(rule => {
      if (rule.rules) {
        this.getBatchIdsFromRuleSet(rule.rules)?.forEach(batchId => {
          batchIds.add(batchId);
        });
      } else {
        if (rule.operator === "batch") {
          batchIds.add(rule.value);
        }
      }
    });

    return batchIds;
  }

  public get qualifierIds(): number[] {
    return Array.from(this.getQualifiersFromRuleSet(this.definition.rules).values());
  }

  private getQualifiersFromRuleSet(rules: any[]): Set<number> {
    const qualifiers: Set<number> = new Set();

    rules.forEach(rule => {
      if (rule.rules) {
        this.getQualifiersFromRuleSet(rule.rules).forEach(qualifierId => {
          qualifiers.add(qualifierId);
        });
      } else {
        qualifiers.add(rule.field);
      }
    });

    return qualifiers;
  }

  public removeIsControlFromRules(rules: any[]): any[] {
    rules.forEach(rule => {
      if (rule.rules) {
        this.removeIsControlFromRules(rule.rules).forEach(r => {
          r.isControl = false;
        });
      } else {
        rule.isControl = false;
      }
    });
    return rules;
  }

  public hasIsControlQualifiers(rules: any[]): boolean {
    return !!rules.find(rule => {
      if (rule.rules) {
        return this.hasIsControlQualifiers(rule.rules);
      } else {
        return rule.isControl;
      }
    });
  }
}
