<radr-edit-popup
  class="edit-popup-form"
  (formSubmit)="okClicked()"
  (formCancel)="cancelClicked()"
  [isProfileBuilder]="mode === modes.ProfileBuilder"
  [isSimplifi]="isSimplifi"
  [isSubmitEnabled]="formGroup?.valid && !formGroup?.pending"
>
  <div popupContent class="scrollable-form">
    <form [formGroup]="formGroup" class="radr-default-form">
      <mat-form-field floatLabel="always" class="radr-default-form__mat-form-field">
        <mat-label>Profile Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error radrFormError [control]="formGroup.get('name')"></mat-error>
        <mat-hint align="left">The name must be 100 characters or fewer.</mat-hint>
      </mat-form-field>

      <mat-form-field
        floatLabel="always"
        class="radr-default-form__mat-form-field"
        *ngIf="this.mode === modes.CampaignBuilder"
      >
        <mat-label>Export Key</mat-label>
        <input matInput formControlName="exportKey" />
        <mat-error radrFormError [control]="formGroup.get('exportKey')"></mat-error>
        <span></span>
        <mat-hint align="left" *ngIf="isCampaignFullAvail"
          >The export key cannot be edited because Addressable TV - Full Avail is toggled on.</mat-hint
        >
        <mat-hint align="left" *ngIf="!isCampaignFullAvail">The export key must be 25 characters or fewer.</mat-hint>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="radr-default-form__mat-form-field">
        <mat-label>Profile Description</mat-label>
        <textarea
          matInput
          formControlName="description"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          maxlength="1000"
          data-test="profile-description"
        ></textarea>
        <mat-error radrFormError [control]="formGroup.get('description')"></mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="radr-default-form__mat-form-field" *ngIf="!isLimitedAccess()">
        <mat-label>Topic(s) </mat-label>
        <mat-select
          multiple
          aria-label="Select an option"
          formControlName="topics"
          [disabled]="(campaign?.isActive || campaign?.isError) && this.mode === modes.CampaignBuilder"
        >
          <mat-option *ngFor="let topic of campaign.topics" [value]="topic?.id">
            {{ topic.name }}
          </mat-option>
        </mat-select>
        <mat-error radrFormError [control]="formGroup.get('topics')"></mat-error>
      </mat-form-field>

      <mat-form-field
        floatLabel="always"
        class="radr-default-form__mat-form-field"
        *ngIf="formGroup.get('dataCatalog').value"
      >
        <mat-label>Data Catalog Category</mat-label>
        <mat-select
          aria-label="Select an option"
          formControlName="dataCatalogCategory"
          (selectionChange)="dataCatalogCategoryChange($event.value)"
        >
          <mat-option *ngFor="let category of dataCatalogCategoryOptions" [value]="category.id">
            {{ category.name }}
          </mat-option>
        </mat-select>
        <mat-error radrFormError [control]="formGroup.get('dataCatalogCategory')"></mat-error>
      </mat-form-field>

      <mat-form-field
        floatLabel="always"
        class="radr-default-form__mat-form-field"
        *ngIf="formGroup.get('dataCatalog').value"
      >
        <mat-label>Data Catalog Sub-Category</mat-label>
        <mat-select
          aria-label="Select an option"
          formControlName="dataCatalogSubcategory"
          (selectionChange)="dataCatalogSubcategory($event.value)"
        >
          <mat-option [value]="null" disabled *ngIf="!dataCatalogSubcategoryOptions?.length"
            >Must Select Category</mat-option
          >
          <mat-option *ngFor="let subcategory of dataCatalogSubcategoryOptions" [value]="subcategory.id">
            {{ subcategory.name }}
          </mat-option>
        </mat-select>
        <mat-error radrFormError [control]="formGroup.get('dataCatalogSubcategory')"></mat-error>
      </mat-form-field>

      <mat-form-field
        floatLabel="always"
        class="radr-default-form__mat-form-field"
        *ngIf="formGroup.get('dataCatalog').value"
      >
        <mat-label>Data Catalog Attribute</mat-label>
        <mat-select
          aria-label="Select an option"
          formControlName="dataCatalogAttribute"
          (selectionChange)="dataCatalogAttributeChange($event.value)"
        >
          <mat-option [value]="null" disabled *ngIf="!dataCatalogAttributeOptions?.length"
            >Must Select Category</mat-option
          >

          <mat-option *ngFor="let attribute of dataCatalogAttributeOptions" [value]="attribute.id">
            {{ attribute.name }}
          </mat-option>
        </mat-select>
        <mat-error radrFormError [control]="formGroup.get('dataCatalogAttribute')"></mat-error>
      </mat-form-field>

      <mat-checkbox
        *ngIf="this.mode === modes.ProfileBuilder && isTuneInTurnedOn && !isLimitedAccess()"
        formControlName="profileType"
        data-test="tune-in-checkbox"
        [disabled]="checkIfTuneInQualifierIsIncluded(profile.definition.rules) && profile.profileType === 2"
        (change)="resetTuneInFields($event)"
        >Tune-In</mat-checkbox
      >

      <mat-checkbox
        *ngIf="isUserAdmin"
        formControlName="dataCatalog"
        [checked]="this.profile.inDataCatalog"
        data-test="data-catalog-checkbox"
        [disabled]="campaign?.isActive || campaign?.isError"
        >Data Catalog</mat-checkbox
      >

      <mat-checkbox
        *ngIf="isUserAdmin"
        formControlName="isCanoeTuneIn"
        [checked]="this.profile.isCanoeTuneIn"
        data-test="canoe-tune-in-checkbox"
        [disabled]="campaign?.isActive || campaign?.isError"
        (change)="resetCanoeTuneInFields($event)"
        >Canoe Tune-In</mat-checkbox
      >

      <mat-form-field
        *ngIf="formGroup.get('isCanoeTuneIn').value"
        floatLabel="always"
        class="radr-default-form__mat-form-field"
      >
        <mat-label>Canoe Segment Name</mat-label>
        <input matInput formControlName="canoeSegmentName" [required]="canoeFieldRequired()" />
        <mat-error radrFormError [control]="formGroup.get('canoeSegmentName')"></mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="formGroup.get('isCanoeTuneIn').value"
        floatLabel="always"
        class="radr-default-form__mat-form-field"
      >
        <mat-label>Canoe Segment Id</mat-label>
        <input matInput formControlName="canoeSegmentId" [required]="canoeFieldRequired()" />
        <mat-error radrFormError [control]="formGroup.get('canoeSegmentId')"></mat-error>
      </mat-form-field>

      <mat-form-field
        floatLabel="always"
        data-test="lookBackTypeIcon"
        class="radr-default-form__mat-form-field"
        *ngIf="this.mode === modes.ProfileBuilder && formGroup.get('profileType').value && isTuneInTurnedOn"
      >
        <mat-label>Lookback Type </mat-label>
        <mat-select
          aria-label="Select an option"
          formControlName="lookBackType"
          [disabled]="campaign?.isActive || campaign?.isError"
          [required]="tuneInFieldRequired('lookBackType')"
          (valueChange)="lookbackTypeChange($event)"
        >
          <mat-option value="Static" data-test="lookBackType">Static</mat-option>
          <mat-option value="Dynamic" data-test="lookBackType">Dynamic</mat-option>
        </mat-select>
        <mat-error radrFormError [control]="formGroup.get('lookBackType')"></mat-error>
      </mat-form-field>

      <div
        class="campaign-start-date"
        *ngIf="
          this.mode === modes.ProfileBuilder &&
          formGroup.get('profileType').value &&
          formGroup.get('lookBackType').value === 'Static' &&
          isTuneInTurnedOn
        "
      >
        <mat-label class="date-label">Lookback Start Date</mat-label>
        <mat-form-field appearance="outline">
          <mat-label>mm / dd / yyyy</mat-label>
          <input
            matInput
            [matDatepicker]="startDate"
            formControlName="lookBackStartDate"
            [max]="formGroup.get('lookBackEndDate')?.value"
            [required]="tuneInFieldRequired('lookBackStartDate')"
          />
          <mat-datepicker #startDate></mat-datepicker>
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-error radrFormError [control]="formGroup.get('lookBackStartDate')"></mat-error>
        </mat-form-field>
      </div>

      <div
        class="campaign-end-date"
        *ngIf="
          this.mode === modes.ProfileBuilder &&
          formGroup.get('profileType').value &&
          formGroup.get('lookBackType').value === 'Static' &&
          isTuneInTurnedOn
        "
      >
        <mat-label class="date-label">Lookback End Date</mat-label>
        <mat-form-field appearance="outline">
          <mat-label>mm / dd / yyyy</mat-label>
          <input
            matInput
            [matDatepicker]="endDate"
            formControlName="lookBackEndDate"
            [min]="formGroup.get('lookbackStartDate')?.value"
            [required]="tuneInFieldRequired('lookBackEndDate')"
          />
          <mat-datepicker #endDate></mat-datepicker>
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-error radrFormError [control]="formGroup.get('lookBackEndDate')"></mat-error>
        </mat-form-field>
      </div>

      <mat-form-field
        floatLabel="always"
        data-test="dynamicLookBackPeriodTypeIcon"
        class="radr-default-form__mat-form-field"
        *ngIf="
          this.mode === modes.ProfileBuilder &&
          formGroup.get('profileType').value &&
          formGroup.get('lookBackType').value === 'Dynamic' &&
          isTuneInTurnedOn
        "
      >
        <mat-label>Dynamic Time Type </mat-label>
        <mat-select
          aria-label="Select an option"
          formControlName="dynamicLookBackPeriodType"
          [disabled]="campaign?.isActive || campaign?.isError"
          [required]="tuneInFieldRequired('dynamicLookBackPeriodType')"
        >
          <mat-option value="Days" data-test="dynamicLookBackPeriodType">Days</mat-option>
          <mat-option value="Months" data-test="dynamicLookBackPeriodType">Months</mat-option>
          <mat-option value="Years" data-test="dynamicLookBackPeriodType">Years</mat-option>
        </mat-select>
        <mat-error radrFormError [control]="formGroup.get('dynamicLookBackPeriodType')"></mat-error>
      </mat-form-field>

      <mat-form-field
        floatLabel="always"
        class="radr-default-form__mat-form-field"
        *ngIf="
          this.mode === modes.ProfileBuilder &&
          formGroup.get('profileType').value &&
          formGroup.get('dynamicLookBackPeriodType').value &&
          formGroup.get('lookBackType').value === 'Dynamic' &&
          isTuneInTurnedOn
        "
      >
        <mat-label>Enter {{ formGroup.get("dynamicLookBackPeriodType").value }}</mat-label>
        <input
          matInput
          type="number"
          min="0"
          formControlName="dynamicLookBackPeriod"
          data-test="dynamicLookBackPeriod"
          [required]="tuneInFieldRequired('dynamicLookBackPeriod')"
        />
        <mat-error radrFormError [control]="formGroup.get('dynamicLookBackPeriod')"></mat-error>
      </mat-form-field>

      <mat-form-field
        floatLabel="always"
        class="radr-default-form__mat-form-field"
        *ngIf="this.mode === modes.ProfileBuilder && formGroup.get('profileType').value && isTuneInTurnedOn"
      >
        <mat-label>Dwell Time (In Minutes)</mat-label>
        <input
          matInput
          formControlName="dwellTimeMinutes"
          data-test="dwellTimeMinutes"
          type="number"
          min="0"
          [required]="tuneInFieldRequired('dwellTimeMinutes')"
        />
        <mat-error radrFormError [control]="formGroup.get('dwellTimeMinutes')"></mat-error>
      </mat-form-field>

      <mat-form-field
        floatLabel="always"
        data-test="tuneInPlatformIcon"
        class="radr-default-form__mat-form-field"
        *ngIf="this.mode === modes.ProfileBuilder && formGroup.get('profileType').value && isTuneInTurnedOn"
      >
        <mat-label>Platform(s) </mat-label>
        <mat-select
          aria-label="Select an option"
          formControlName="platforms"
          [disabled]="campaign?.isActive || campaign?.isError"
          [required]="tuneInFieldRequired('platforms')"
          multiple
        >
          <mat-option
            *ngFor="let platform of campaign.platforms"
            [value]="platform.id"
            data-test="tuneInPlatformOption"
          >
            {{ platform?.name }}
          </mat-option>
        </mat-select>
        <mat-error radrFormError [control]="formGroup.get('platforms')"></mat-error>
      </mat-form-field>

      <mat-form-field
        floatLabel="always"
        class="radr-default-form__mat-form-field"
        *ngIf="qualifierHasSegmentId && segmentIdControlOptions.length && this.mode === modes.CampaignBuilder"
      >
        <mat-label>Data Source Segment ID *</mat-label>
        <mat-select
          aria-label="Select an option"
          *ngIf="segmentIdControlOptions.length > 1"
          formControlName="segmentId"
          [disabled]="campaign?.isActive || campaign?.isError"
        >
          <mat-option *ngFor="let option of segmentIdControlOptions" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <input matInput formControlName="segmentId" *ngIf="segmentIdControlOptions.length === 1" readonly />
        <mat-error radrFormError [control]="formGroup.get('segmentId')"></mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="radr-default-form__mat-form-field" *ngIf="campaign?.isControlActive">
        <mat-label>Control Group Size *</mat-label>
        <input matInput type="number" formControlName="controlPercentage" />
        <span matSuffix>%</span>
        <mat-error radrFormError [control]="formGroup.get('controlPercentage')"></mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="radr-default-form__mat-form-field" *ngIf="enableFixedCapField">
        <mat-label>Fixed Cap *</mat-label>
        <input matInput type="number" formControlName="capFixed" />
        <mat-error radrFormError [control]="formGroup.get('capFixed')"></mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="radr-default-form__mat-form-field" *ngIf="enablePercentageField">
        <mat-label>Percentage Cap *</mat-label>
        <input matInput type="number" formControlName="capPercentage" />
        <span matSuffix>%</span>
        <mat-error radrFormError [control]="formGroup.get('capPercentage')"></mat-error>
      </mat-form-field>
    </form>
  </div>
</radr-edit-popup>
