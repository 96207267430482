import { Component, EventEmitter, HostListener, Input, Output, ViewChild, ElementRef, OnInit } from "@angular/core";
import { map, take } from "rxjs/operators";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import isEqual from "lodash.isequal";
import {
  QueryBuilderComponent,
  QueryBuilderConfig,
  QueryBuilderClassNames,
  Option,
  Rule
} from "@shared/components/angular-query-builder";
import { QualifiersService } from "@shared/services/qualifiers.service";
import { SubscriberService } from "@shared/services/subscriber.service";
import { ProfileViewModel, Qualifier, CappingLevel, CampaignViewModel, ProfileAudience } from "radr-shared";
import { EditProfilePopupComponent } from "../edit-profile-popup/edit-profile-popup.component";
import { LoadingService } from "@shared/services/loading.service";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { ProfilesService } from "@shared/services/profiles.service";
import { CampaignBuilderModes } from "@shared/enums/campaign-builder-mode.enum";
import closeDialogAndNotify from "@shared/utils/closeDialogAndNotify";
import { SnackBarStatus } from "@shared/utils/notify";
import { DialogModalComponent } from "@shared/components/dialog-modal/dialog-modal.component";
import { CdkDragMove } from "@angular/cdk/drag-drop";
import { ProgrammerError } from "@shared/models";
import { RoleAccessService } from "@shared/services/role-access.service";
import { QualfierProfileListModalComponent } from "src/app/modules/data-sources/components/qualifier-profile-list-modal/qualifier-profile-list-modal.component";
import { Observable } from "rxjs";
import { AddTestMacsModalComponent } from "../add-test-macs-modal/add-test-macs-modal.component";
import { ApplicationSettingsService } from "@shared/services/application-settings.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

export interface PriorityChange {
  previousPriority: number;
  newPriority: number;
}

@Component({
  selector: "radr-profile-definition",
  templateUrl: "./profile-definition.component.html",
  styleUrls: ["./profile-definition.component.scss"]
})
export class ProfileDefinitionComponent implements OnInit {
  @Input()
  set qualifiers(qualifiers: Qualifier[]) {
    qualifiers.forEach(qualifier => {
      this.config.fields[qualifier.id] = {
        name: qualifier.name,
        value: qualifier.id.toString(),
        type: qualifier.dataType
      };
    });
  }
  @Input()
  set definition(val: any) {
    if (!isEqual(val, this.definitionValue)) {
      this.definitionValue = val;
    }
  }
  get definition(): any {
    return this.definitionValue;
  }
  @Input()
  set maxPriority(val: number) {
    this.priorities = [0, ...Array.from({ length: val }, (v, i) => i + 1)];
  }
  @Input()
  set profile(val: ProfileViewModel) {
    this.profileValue = val;
  }
  get profile(): ProfileViewModel {
    return this.profileValue;
  }

  @Input() hideHeader: boolean = false;
  @Input() hideCounts: boolean = false;
  @Input() canDrag: boolean = true;
  @Input() disabled: boolean = false;
  @Input() isPreview: boolean = false;

  public get isDisabled(): boolean {
    return (
      this.disabled ||
      !this.roleAccessService.canEditEntity(this.campaign) ||
      this.campaignBuilderService.isQualifierCountsLoading
    );
  }

  public get showTestMacsButton(): boolean {
    return (
      this.mode === CampaignBuilderModes.CampaignBuilder &&
      this.campaign.executionPlatformGroups?.some(ep => ep?.executionPlatformId === 1) &&
      this.roleAccessService.canEditEntity(this.campaign) === true
    );
  }

  public get profileWarning(): string {
    const p: ProfileViewModel = this.profile;
    return this.profilesService.getCurrentProfileErrors(p, this.mode);
  }

  public audienceEnabled: boolean;

  constructor(
    public subscriberService: SubscriberService,
    private qualifiersService: QualifiersService,
    private loadingService: LoadingService,
    public campaignBuilderService: CampaignBuilderService,
    private profilesService: ProfilesService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public roleAccessService: RoleAccessService,
    public applicationSettingsService: ApplicationSettingsService,
    private fb: FormBuilder
  ) {}
  public hovering: boolean = false;

  @Input() public dropListGuid: string;

  @ViewChild("queryBuilder") queryBuilder: QueryBuilderComponent;
  @ViewChild(EditProfilePopupComponent) editProfilePopover: EditProfilePopupComponent;

  public modes: typeof CampaignBuilderModes = CampaignBuilderModes;
  public segmentIds: string[];
  public profileAudience: ProfileAudience;

  @Input() mode: CampaignBuilderModes = CampaignBuilderModes.CampaignBuilder;
  @Input() showDragHandleOnHover: boolean = false;

  public definitionValue: any;
  @Output() definitionChange: EventEmitter<ProfileViewModel> = new EventEmitter();

  public priorities: number[] = [1];
  private profileValue: ProfileViewModel;

  @Input() allowDefaultProfiles: boolean = true;

  @Output() removed: EventEmitter<{ index: number }> = new EventEmitter();
  @Output() priorityUpdated: EventEmitter<PriorityChange> = new EventEmitter();
  @Output() addedNewProfile: EventEmitter<ProfileViewModel> = new EventEmitter<ProfileViewModel>();
  @Output() deleted: EventEmitter<{ index: number }> = new EventEmitter();

  public campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();

  public config: QueryBuilderConfig = {
    fields: {},
    getInputType: this.customGetInputType.bind(this),
    getOptions: this.customGetOptions.bind(this)
  };
  public customClassNames: QueryBuilderClassNames = {
    switchRow: "query-builder-switch-group",
    buttonGroup: "query-builder-button-group",
    removeIcon: "query-builder-remove-icon-group",
    profileQualifierRow: "query-builder-profile-qualifier",
    connector: "query-builder-connector"
  };

  public customOperatorMap: { [key: string]: string[] } = {
    values: ["exists"],
    hhid: ["exists"],
    canoe: ["exists"],
    viacomSyntheticId: ["exists"],
    nbcuSyntheticId: ["exists"],
    blockgraphId: ["exists"],
    string: ["in", "not in", "=", "!=", "batch"],
    number: ["=", "!=", ">", ">=", "<", "<="],
    date: ["=", "!=", ">", ">=", "<", "<="],
    boolean: ["is"],
    rootProfile: ["includes", "excludes"],
    activeProfile: ["includes", "excludes"]
  };

  public audienceFormGroup: FormGroup;

  @HostListener("mouseenter")
  public mouseenterListener(): void {
    this.hovering = true;
  }

  @HostListener("mouseleave")
  public mouseleaveListener(): void {
    this.hovering = false;
  }

  ngOnInit(): void {
    this.segmentIds = Array.from(this.qualifiersService.getSegmentIdNameMapFromRuleSet(this.profile.definition).keys());
    this.audienceEnabled = this.applicationSettingsService.audienceEnabledFlag;
    this.createAudienceForm(this.profileAudience);
  }

  public getOptionsForStringQualifier(id: number): Observable<string[]> {
    return this.qualifiersService.getDistinctQualifierValues(id).pipe(
      map(options =>
        options
          .filter(option => !!option)
          .sort((a, b) =>
            a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: "base"
            })
          )
      )
    );
  }

  public isBackendSearch(rule: Rule): boolean {
    return rule.qualifierName === "Service Zip+4";
  }

  public getQualifierSearchEndpoint(rule: Rule): string {
    return rule.qualifierName === "Service Zip+4"
      ? this.qualifiersService.getDistinctQualifierValuesEndpoint(rule.field)
      : "";
  }

  public updateSegmentIdAndSegmentName(): void {
    if (this.mode === CampaignBuilderModes.CampaignBuilder && !this.profile.isCanoeTuneIn) {
      const segIdNameMap: Map<string, string> = this.qualifiersService.getSegmentIdNameMapFromRuleSet(
        this.profile.definition
      );
      if (segIdNameMap.size === 1) {
        this.profile.segmentId = segIdNameMap.keys().next().value;
        this.profile.segmentName = segIdNameMap.values().next().value;
      } else {
        this.profile.segmentId = null;
        this.profile.segmentName = null;
      }
      this.segmentIds = Array.from(segIdNameMap.keys());
    }
  }

  public buildSelectAutocompleteOptions(options: Option[] = []): string[] | number[] {
    return options.map(option => option.value);
  }

  public customGetOptions(field: string, context?: any): Option[] {
    if (!this.config.fields[field]) {
      throw new ProgrammerError(
        `No configuration for field '${field}' could be found! Please add it to config.fields.`,
        "ProfileDefinitionComponent.customGetOptions"
      );
    }
    return this.config.fields[field].options;
  }

  public customGetInputType(field: string, operator: string, tuneInType?: string): string {
    if (!this.config?.fields[field]) {
      throw new ProgrammerError(
        `No configuration for field '${field}' could be found! Please add it to config.fields.`,
        "ProfileDefinitionComponent.customGetInputType"
      );
    }
    if (tuneInType === "Program") {
      return "tune-in-program";
    }

    if (tuneInType === "Station") {
      return "tune-in-station";
    }

    if (tuneInType === "Genre") {
      return "tune-in-genre";
    }

    if (tuneInType === "Scheduling") {
      return "tune-in-scheduling";
    }

    const type: string = this.config?.fields[field]?.type;
    switch (operator) {
      case "is":
        return "category";
      case "in":
      case "not in":
        return type === "category" || type === "boolean" || type === "string" ? "multiselect" : type;
      case "exists":
        return "category";
      case "batch":
        return "batch";
      default:
        return type;
    }
  }

  public handleEditPopoverClose(): void {
    this.editProfilePopover.cancelClicked();
  }

  createAudienceForm(profileAudience?: ProfileAudience): void {
    this.audienceFormGroup = this.fb.group({
      target: new FormControl(profileAudience?.target, [Validators.required]),
      control: new FormControl(profileAudience?.control)
    });
  }

  openDeleteProfileDialog(): void {
    const dialogRef: MatDialogRef<DialogModalComponent> = this.dialog.open(DialogModalComponent, {
      data: {
        title: "Delete Profile",
        content: "Are you sure you want to delete this profile?\n\nDeleting permanently removes it from RADR.",
        submitButtonText: "OK",
        cancelButtonText: "Cancel",
        dialogType: "Warning"
      }
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(deleteRequested => {
        if (deleteRequested) {
          this.loadingService.setLoading(true);
          this.profilesService.deleteProfile(this.profile.id).subscribe(
            () => {
              this.loadingService.setLoading(false);
              closeDialogAndNotify(this.dialog, this.snackBar, "Delete successful", SnackBarStatus.Success);
              this.removeDeletedProfileFromSelectedCampaign();
            },
            err => {
              this.loadingService.setLoading(false);
              closeDialogAndNotify(this.dialog, this.snackBar, "Delete failed", SnackBarStatus.Error);
            }
          );
        }
      });
  }

  public deleteProfile(): void {
    if (this.profile?.id && this.mode === CampaignBuilderModes.ProfileBuilder) {
      this.profilesService.getProfilesByQualifierId("p" + this.profile.id.toString()).subscribe(profiles => {
        if (profiles.length > 0) {
          const ref: MatDialogRef<QualfierProfileListModalComponent> = this.dialog.open(
            QualfierProfileListModalComponent,
            {
              data: {
                title: "Can't Delete Root Profile",
                profiles,
                type: "root profile"
              }
            }
          );

          ref
            .beforeClosed()
            .pipe(take(1))
            .subscribe(() => {});
        } else {
          this.openDeleteProfileDialog();
        }
      });
    } else {
      this.openDeleteProfileDialog();
    }
  }

  public openRemoveProfileDialog(): void {
    const displayText: string =
      this.mode === CampaignBuilderModes.ProfileBuilder
        ? "Are you sure you want to remove this profile? Any unsaved changes will be lost."
        : "Are you sure you want to remove this profile from the campaign?\n\nIt will still exist in RADR.";
    const dialogRef: MatDialogRef<DialogModalComponent> = this.dialog.open(DialogModalComponent, {
      data: {
        title: "Remove Profile",
        content: displayText,
        submitButtonText: "OK",
        cancelButtonText: "Cancel",
        dialogType: "Warning"
      }
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        removeRequested => {
          if (removeRequested) {
            if (this.profile.id) {
              this.loadingService.setLoading(true);
              this.profilesService.patchProfile(this.profile.id, { campaignId: null }).subscribe(() => {
                this.removeCurrentProfileFromSelectedCampaign();
                this.loadingService.setLoading(false);
                closeDialogAndNotify(this.dialog, this.snackBar, "Remove Profile successful", SnackBarStatus.Success);
              });
            } else {
              this.removeCurrentProfileFromSelectedCampaign();
              this.loadingService.setLoading(false);
              closeDialogAndNotify(this.dialog, this.snackBar, "Remove Profile successful", SnackBarStatus.Success);
            }
          }
        },
        err => {
          this.loadingService.setLoading(false);
          closeDialogAndNotify(this.dialog, this.snackBar, "Remove Profile failed", SnackBarStatus.Error);
        }
      );
  }

  public removeProfile(): void {
    this.openRemoveProfileDialog();
  }

  public removeCurrentProfileFromSelectedCampaign(): void {
    const index: number = this.campaignBuilderService
      .getSelectedItem()
      .profiles.findIndex(p => p.priority === this.profile.priority);
    this.removed.emit({ index });
    this.campaignBuilderService.loadCounts();
    this.updateSegmentIdAndSegmentName();
  }

  public removeDeletedProfileFromSelectedCampaign(): void {
    const index: number = this.campaignBuilderService
      .getSelectedItem()
      .profiles.findIndex(p => p.priority === this.profile.priority);
    this.deleted.emit({ index });
    this.campaignBuilderService.loadCounts();
    this.updateSegmentIdAndSegmentName();
  }

  public cloneProfile(profile: ProfileViewModel): void {
    const newProfile: ProfileViewModel = this.campaignBuilderService.addNewProfile(profile, profile.priority + 1);
    this.addedNewProfile.emit(newProfile);
    this.campaignBuilderService.loadCounts();
  }

  public addTestMacs(): void {
    const dialogRef: MatDialogRef<AddTestMacsModalComponent> = this.dialog.open(AddTestMacsModalComponent, {
      data: { title: "Add Test MAC Addresses", profile: this.profile }
    });
  }

  public priorityChanged(args: { oldPriority?: number; newPriority?: number }): void {
    this.priorityUpdated.emit({
      previousPriority: Number.isInteger(args.oldPriority) ? args.oldPriority : this.profile.priority,
      newPriority: Number.isInteger(args.newPriority) ? args.newPriority : this.profile.priority
    });
    if (!this.campaignBuilderService.getSelectedItem().isGross) {
      this.campaignBuilderService.loadCounts();
      this.updateSegmentIdAndSegmentName();
    }
  }

  public isCampaignControlSource(): boolean {
    return this.campaignBuilderService.getSelectedItem().isControlActive;
  }

  public openMenu(anchor: any): void {
    anchor.popover.anchor = anchor;
    anchor.popover.open();
  }

  public isCappingActivated(): boolean {
    return this.campaignBuilderService.getSelectedItem()?.isCappingActive;
  }

  public isCappingLevelCampaignFixed(): boolean {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    return campaign?.isCappingActive && campaign?.cappingLevel === CappingLevel.CampaignFixed;
  }

  public isCappingLevelProfileFixed(): boolean {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    return campaign?.isCappingActive && campaign?.cappingLevel === CappingLevel.ProfileFixed;
  }

  public isCappingLevelCampaignPercentage(): boolean {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    return campaign?.isCappingActive && campaign?.cappingLevel === CappingLevel.CampaignPercentage;
  }

  public isCappingLevelProfilePercentage(): boolean {
    const campaign: CampaignViewModel = this.campaignBuilderService.getSelectedItem();
    return campaign?.isCappingActive && campaign?.cappingLevel === CappingLevel.ProfilePercentage;
  }

  public onDragMove(event: CdkDragMove<any>): void {
    const nodeMovePreview: any = new ElementRef<HTMLElement>(document.getElementById(`${this.dropListGuid}-preview`));
    const xPos: number = event.pointerPosition.x - 900;
    const yPos: number = event.pointerPosition.y - 20;
    if (nodeMovePreview?.nativeElement) {
      nodeMovePreview.nativeElement.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
    }
  }

  public toggleCollapse(): void {
    this.queryBuilder.toggleCollapse();
  }
}
