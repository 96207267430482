import { Component, Input, Optional, OnInit, OnDestroy, OnChanges } from "@angular/core";
import { SatPopover } from "@ncstate/sat-popover";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import pick from "lodash.pick";
import toLower from "lodash.tolower";
import { Observable, of, Subject } from "rxjs";
import { take } from "rxjs/operators";
import {
  CampaignStatusEnum,
  CampaignViewModel,
  CappingLevel,
  ProfileViewModel,
  SimplifiCategory,
  SimplifiSubcategory
} from "radr-shared";
import { ControlOption } from "@shared/components/form/form.component";
import {
  forbiddenNameValidatorFactory,
  forbiddenSpacesValidator,
  generateRequiredMaxLengthValidators,
  startsWithAlphaNumericValidator
} from "@shared/components/form/form-util";
import { defaultControlPercentage, maximumPercentage, minimumPercentage } from "@shared/consts";
import { CampaignBuilderService } from "@shared/services/campaign-builder.service";
import { ProfilesService } from "@shared/services/profiles.service";
import { takeUntil } from "rxjs/operators";
import { CampaignBuilderModes } from "@shared/enums/campaign-builder-mode.enum";
import { QualifiersService } from "@shared/services/qualifiers.service";
import { RoleAccessService } from "@shared/services/role-access.service";
import { ApplicationSettingsService } from "@shared/services/application-settings.service";
import { SimplifiService } from "@shared/services/simplifi.service";
import { SimplifiAttribute } from "radr-shared/src/models/simplifi-attribute.model";

@Component({
  selector: "radr-edit-profile-popup",
  templateUrl: "./edit-profile-popup.component.html",
  styleUrls: ["edit-profile-popup.component.scss"]
})
export class EditProfilePopupComponent implements OnDestroy, OnInit, OnChanges {
  public get dataCatalogCategoryOptions(): SimplifiCategory[] {
    return this._dataCatalogCategoryOptions.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }
  public set dataCatalogCategoryOptions(categories: SimplifiCategory[]) {
    this._dataCatalogCategoryOptions = categories;
  }
  public get dataCatalogSubcategoryOptions(): SimplifiSubcategory[] {
    return this._dataCatalogSubcategoryOptions.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }
  public set dataCatalogSubcategoryOptions(subcategories: SimplifiSubcategory[]) {
    this._dataCatalogSubcategoryOptions = subcategories;
  }
  public get dataCatalogAttributeOptions(): SimplifiAttribute[] {
    return this._dataCatalogAttributeOptions.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }
  public set dataCatalogAttributeOptions(attributes: SimplifiAttribute[]) {
    this._dataCatalogAttributeOptions = attributes;
  }

  constructor(
    @Optional() public popover: SatPopover,
    private campaignBuilderService: CampaignBuilderService,
    private profileService: ProfilesService,
    private qualifiersService: QualifiersService,
    private roleAccessService: RoleAccessService,
    private applicationSettingsService: ApplicationSettingsService,
    private simplifiService: SimplifiService
  ) {}
  public modes: typeof CampaignBuilderModes = CampaignBuilderModes;
  @Input() mode: string = CampaignBuilderModes.CampaignBuilder;

  @Input() profile: ProfileViewModel = new ProfileViewModel();

  @Input() segmentIds: string[] = [];
  public segmentIdControlOptions: ControlOption[];
  public originalName: string;
  public originalExportKey: string;
  public initialTopics: number[];
  public formGroup: FormGroup;

  public isCampaignFullAvail: boolean = false;
  public qualifierHasSegmentId: boolean;
  public enablePercentageField: boolean = false;
  public enableFixedCapField: boolean = false;
  public campaign: CampaignViewModel;
  public isTuneInTurnedOn: boolean;
  public isUserAdmin: boolean = false;
  private cleanUp: Subject<void> = new Subject<void>();

  public isSimplifi: boolean = this.campaignBuilderService.doesCampaignHaveSimplifi();
  private _dataCatalogCategoryOptions: SimplifiCategory[] = [];
  private dataCatalogSubcategories: SimplifiSubcategory[] = [];
  private _dataCatalogSubcategoryOptions: SimplifiSubcategory[] = [];
  private dataCatalogAttributes: SimplifiAttribute[] = [];
  private _dataCatalogAttributeOptions: SimplifiAttribute[] = [];

  ngOnInit(): void {
    this.campaign = this.campaignBuilderService.getSelectedItem();
    this.initialTopics = this.profile.topics.map(topic => {
      return topic.id;
    });
    this.applicationSettingsService.getApplicationSetting("tunein_enabled").subscribe(val => {
      this.isTuneInTurnedOn = val.value === "true";
    });
    this.isUserAdmin = this.roleAccessService.isAdmin();
    this.buildForm();
    this.simplifiService
      .getSimplifiOptions()
      .pipe(take(1))
      .subscribe(result => {
        this.dataCatalogCategoryOptions = result?.categories;
        this.dataCatalogSubcategories = result?.subcategories;
        this.dataCatalogAttributes = result?.attributes;
        this.dataCatalogSubcategoryOptions = this.filterDataCatalogSubcategoryOptions();
        this.dataCatalogAttributeOptions = this.filterDataCatalogAttributeOptions();
      });

    if (this.popover) {
      // Once the edit popup is opened, check if the export key can be edited
      this.popover.opened.pipe(takeUntil(this.cleanUp)).subscribe(() => {
        this.buildForm();
        const exportKeyFormField: FormControl = this.formGroup.get("exportKey") as FormControl;
        if (this.campaign.isCampaignActivated) {
          exportKeyFormField.disable();
        } else {
          if (
            this.campaignBuilderService.isCampaignFullAvail() &&
            this.campaignBuilderService.getSelectedItem().groupName
          ) {
            exportKeyFormField.disable();
            this.isCampaignFullAvail = true;
            exportKeyFormField.setValue(this.profile.exportKey);
          } else if (!this.campaignBuilderService.isCampaignFullAvail()) {
            exportKeyFormField.enable();
            exportKeyFormField.setValue(this.profile.exportKey);
          }
        }

        const segmentIdField: FormControl = this.formGroup.get("segmentId") as FormControl;
        if (
          this.qualifiersService.getUniqueSegmentIdsFromRuleSet(this.profile.definition).length > 0 &&
          this.mode === CampaignBuilderModes.CampaignBuilder
        ) {
          this.qualifierHasSegmentId = true;
          segmentIdField.enable();
          segmentIdField.setValidators([Validators.required]);
          const options: ControlOption[] = this.segmentIdControlOptions;
          if (options.length === 1) {
            segmentIdField.patchValue(options[0].value);
          } else {
            segmentIdField.patchValue(this.profile.segmentId);
          }
        } else {
          this.qualifierHasSegmentId = false;
          this.formGroup.get("segmentId").patchValue(null);
        }
        if (this.campaign.isActive) {
          segmentIdField.disable();
        }

        const capFixedField: FormControl = this.formGroup.get("capFixed") as FormControl;
        const capPercentageField: FormControl = this.formGroup.get("capPercentage") as FormControl;

        capFixedField.reset();
        capFixedField.patchValue(this.profile.capFixed || null);

        capPercentageField.reset();
        capPercentageField.patchValue(this.profile.capPercentage || null);

        if (this.campaign.isCappingActive) {
          if (this.campaign.cappingLevel === CappingLevel.ProfilePercentage) {
            if (!this.campaign.isCampaignActivated && this.campaign.cappingLevel) {
              this.enablePercentageField = true;
              this.enableFixedCapField = false;
              capPercentageField.enable();
              this.formGroup.get("capFixed").clearValidators();
              this.formGroup.get("capFixed").updateValueAndValidity();
            }
          } else if (this.campaign.cappingLevel === CappingLevel.ProfileFixed) {
            if (!this.campaign.isCampaignActivated) {
              this.enableFixedCapField = true;
              this.enablePercentageField = false;
              capFixedField.enable();
              this.formGroup.get("capPercentage").clearValidators();
              this.formGroup.get("capPercentage").updateValueAndValidity();
            }
          } else {
            this.enableFixedCapField = false;
            this.enablePercentageField = false;
            capFixedField.disable();
            capPercentageField.disable();
          }
        } else {
          this.enableFixedCapField = false;
          this.enablePercentageField = false;
          capFixedField.disable();
          capPercentageField.disable();
        }

        // Enable/Disable control group form control
        const controlGroupSizeField: FormControl = this.formGroup.get("controlPercentage") as FormControl;
        controlGroupSizeField.setValue(this.profile.controlPercentage);
        if (this.campaign.isControlActive) {
          controlGroupSizeField.disable();
          if (this.campaign.isControlLevelCampaign || this.campaign.isCampaignActivated) {
            controlGroupSizeField.disable();
          } else {
            controlGroupSizeField.enable();
          }
        }
        const entityToCheck: ProfileViewModel | CampaignViewModel =
          this.mode === CampaignBuilderModes.CampaignBuilder ? this.campaign : this.profile;
        if (!this.roleAccessService.canEditEntity(entityToCheck)) {
          this.formGroup.disable();
        }
      });
    }
    this.originalName = this.profile.name || "";
    this.originalExportKey = this.profile.exportKey || "";
  }

  ngOnChanges(): void {
    this.segmentIdControlOptions = this.segmentIds
      ? this.segmentIds.map(segmentId => ({
          value: segmentId,
          label: segmentId
        }))
      : [];
  }

  ngOnDestroy(): void {
    this.cleanUp.next();
    this.cleanUp.complete();
  }

  buildForm(): void {
    if (!this.dataCatalogCategoryOptions.length && this.profile.simplifiCategory) {
      this.dataCatalogCategoryOptions = [this.profile.simplifiCategory];
    }
    if (!this.dataCatalogSubcategoryOptions.length && this.profile.simplifiSubcategory) {
      this.dataCatalogSubcategoryOptions = [this.profile.simplifiSubcategory];
    }
    if (!this.dataCatalogAttributeOptions.length && this.profile.simplifiAttribute) {
      this.dataCatalogAttributeOptions = [this.profile.simplifiAttribute];
    }
    this.formGroup = new FormGroup(
      {
        name: new FormControl(
          this.profile.name,
          [...generateRequiredMaxLengthValidators(100, { isRequired: true }), startsWithAlphaNumericValidator],
          [forbiddenNameValidatorFactory(this.isProfileNameUnique.bind(this))]
        ),
        description: new FormControl(this.profile.description, [startsWithAlphaNumericValidator]),
        exportKey: new FormControl(this.profile.exportKey || null),
        topics: new FormControl(this.initialTopics),
        profileType: new FormControl(this.profile.profileType === 2),
        lookBackType: new FormControl(this.profile.lookBackType),
        dynamicLookBackPeriod: new FormControl(this.profile.dynamicLookBackPeriod),
        dynamicLookBackPeriodType: new FormControl(this.profile.dynamicLookBackPeriodType),
        lookBackStartDate: new FormControl(this.profile.lookBackStartDate),
        lookBackEndDate: new FormControl(this.profile.lookBackEndDate),
        dwellTimeMinutes: new FormControl(this.profile.dwellTimeMinutes ?? 6),
        platforms: new FormControl(this.profile.platforms ? this.profile.platforms.map(p => p.id) : null),
        segmentId: new FormControl(this.profile.segmentId),
        controlPercentage: new FormControl(this.profile.controlPercentage || defaultControlPercentage),
        capFixed: new FormControl(this.profile.capFixed),
        capPercentage: new FormControl({ value: this.profile.capPercentage, disabled: true }),
        dataCatalogCategory: new FormControl(this.profile?.simplifiCategory?.id),
        dataCatalogSubcategory: new FormControl(this.profile?.simplifiSubcategory?.id),
        dataCatalogAttribute: new FormControl(this.profile?.simplifiAttribute?.id),
        dataCatalog: new FormControl(this.profile.inDataCatalog),
        isCanoeTuneIn: new FormControl(this.profile.isCanoeTuneIn),
        canoeSegmentId: new FormControl(this.profile.segmentId),
        canoeSegmentName: new FormControl(this.profile.segmentName)
      },
      (formGroup: FormGroup) => {
        if (this.mode === CampaignBuilderModes.CampaignBuilder) {
          formGroup
            .get("exportKey")
            .setValidators([
              ...generateRequiredMaxLengthValidators(25, { isRequired: false }),
              forbiddenSpacesValidator,
              startsWithAlphaNumericValidator
            ]);

          formGroup
            .get("exportKey")
            .setAsyncValidators([forbiddenNameValidatorFactory(this.isProfileExportKeyUnique.bind(this))]);
        }
        if (this.profile.inDataCatalog) {
          formGroup.get("dataCatalogCategory").setValidators([Validators.required]);
          formGroup.get("dataCatalogSubcategory").setValidators([Validators.required]);
          formGroup.get("dataCatalogAttribute").setValidators([Validators.required]);
        }

        if (this.segmentIdControlOptions?.length > 1) {
          formGroup.get("segmentId").setValidators(Validators.required);
        }
        if (this.enableFixedCapField) {
          formGroup.get("capFixed").setValidators([Validators.required, Validators.min(0), Validators.max(1000000000)]);
        }
        if (this.enablePercentageField) {
          formGroup
            .get("capPercentage")
            .setValidators([Validators.required, Validators.max(maximumPercentage), Validators.min(minimumPercentage)]);
        }
        if (this.campaign?.isControlActive) {
          formGroup
            .get("controlPercentage")
            .setValidators([Validators.required, Validators.max(maximumPercentage), Validators.min(minimumPercentage)]);
        }
        return null;
      }
    );
    this.formGroup.get("dataCatalog").valueChanges.subscribe(val => {
      if (val) {
        this.formGroup.controls["dataCatalogCategory"].setValidators([Validators.required]);
        this.formGroup.controls["dataCatalogSubcategory"].setValidators([Validators.required]);
        this.formGroup.controls["dataCatalogAttribute"].setValidators([Validators.required]);
      } else {
        this.formGroup.controls["dataCatalogCategory"].clearValidators();
        this.formGroup.controls["dataCatalogSubcategory"].clearValidators();
        this.formGroup.controls["dataCatalogAttribute"].clearValidators();
      }
      this.formGroup.controls["dataCatalogCategory"].updateValueAndValidity();
      this.formGroup.controls["dataCatalogSubcategory"].updateValueAndValidity();
      this.formGroup.controls["dataCatalogAttribute"].updateValueAndValidity();
    });
  }

  isLimitedAccess(): boolean {
    return this.roleAccessService.isLimitedAccess();
  }

  isProfileNameUnique(name: string): Observable<boolean> {
    // Check if name is original name or not
    if (name.toLowerCase() === this.originalName.toLowerCase()) {
      return of(true);
    }

    // Check name against saved & unsaved profile names
    const isProfileNameUniqueAgainstUnsavedNames: boolean = this.campaignBuilderService
      .getSelectedItem()
      .profiles.every(profileData => (profileData.name || "").toLowerCase() !== name.toLowerCase());
    if (!isProfileNameUniqueAgainstUnsavedNames) {
      return of(false);
    }

    // Check name against saved profile names
    return this.profileService.isProfileNameUnique(name);
  }

  isProfileExportKeyUnique(exportKey: string): Observable<boolean> {
    const lowerCasedExportKey: string = toLower(exportKey);
    if (!exportKey || lowerCasedExportKey === toLower(this.originalExportKey)) {
      return of(true);
    }

    const matchesUnsavedExportKey: boolean = this.campaignBuilderService
      .getSelectedItem()
      .profiles.some(({ exportKey: unsavedExportKey }) => toLower(unsavedExportKey) === lowerCasedExportKey);
    if (matchesUnsavedExportKey) {
      return of(false);
    }

    // Check export key against saved profile export keys
    return this.profileService.isProfileExportKeyUnique(exportKey);
  }

  okClicked(): void {
    const tuneInCountFields: string[] = [
      "lookBackType",
      "dwellTimeMinutes",
      "lookBackStartDate",
      "lookBackEndDate",
      "dynamicLookBackPeriod",
      "dynamicLookBackPeriodType",
      "platforms"
    ];
    const formValue: any = this.formGroup.getRawValue();
    this.originalName = formValue.name;
    this.profile.name = formValue.name;
    this.profile.topics = formValue.topics.map(id => {
      return { id };
    });
    this.profile.profileType = formValue.profileType === true ? 2 : 1;
    this.profile.lookBackType = formValue.lookBackType;
    this.profile.dwellTimeMinutes = formValue.dwellTimeMinutes;
    this.profile.lookBackStartDate = formValue.lookBackStartDate;
    this.profile.lookBackEndDate = formValue.lookBackEndDate;
    this.profile.dynamicLookBackPeriod = formValue.dynamicLookBackPeriod;
    this.profile.dynamicLookBackPeriodType = formValue.dynamicLookBackPeriodType;
    this.profile.platforms = formValue.platforms
      ? formValue.platforms.map(p => {
          return { id: p };
        })
      : null;
    this.profile.description = formValue.description ? formValue.description.replace(/\n/g, " ") : null;
    this.originalExportKey = formValue.exportKey;
    this.profile.exportKey = formValue.exportKey;
    this.profile.segmentId = formValue.segmentId;
    this.profile.inDataCatalog = formValue.dataCatalog;
    if (this.profile.inDataCatalog) {
      this.profile.simplifiCategory = this.dataCatalogSubcategories.find(c => c.id === formValue.dataCatalogCategory);
      this.profile.simplifiSubcategory = this.dataCatalogSubcategories.find(
        s => s.id === formValue.dataCatalogSubcategory
      );
      this.profile.simplifiAttribute = this.dataCatalogAttributes.find(a => a.id === formValue.dataCatalogAttribute);
    } else {
      if (this.profile.simplifiCategory) {
        delete this.profile.simplifiCategory;
      }
      if (this.profile.simplifiSubcategory) {
        delete this.profile.simplifiSubcategory;
      }
      if (this.profile.simplifiAttribute) {
        delete this.profile.simplifiAttribute;
      }
    }

    if (this.profile.segmentId) {
      const segIdNameMap: Map<string, string> = this.qualifiersService.getSegmentIdNameMapFromRuleSet(
        this.profile.definition
      );
      this.profile.segmentName = segIdNameMap.get(this.profile.segmentId);
    }
    this.profile.isCanoeTuneIn = formValue.isCanoeTuneIn;
    this.profile.segmentName = formValue.isCanoeTuneIn ? formValue.canoeSegmentName : this.profile.segmentName;
    this.profile.segmentId = formValue.isCanoeTuneIn ? formValue.canoeSegmentId : this.profile.segmentId;
    if (this.profile.capFixed !== formValue.capFixed) {
      this.profile.capFixed = formValue.capFixed;
      this.campaignBuilderService.loadCounts();
    } else if (this.profile.capPercentage !== formValue.capPercentage) {
      this.profile.capPercentage = formValue.capPercentage;
      this.campaignBuilderService.loadCounts();
    }

    if (formValue.controlPercentage && +formValue.controlPercentage >= 0) {
      this.profile.controlPercentage = +formValue.controlPercentage;
      this.campaignBuilderService.loadCounts();
    } else {
      this.profile.controlPercentage = null;
    }

    if (this.popover) {
      this.popover.close();
    }

    for (const field of tuneInCountFields) {
      if (this.profile[field] !== formValue[field]) {
        this.campaignBuilderService.loadCounts();
        break;
      }
    }
    this.profile.status = this.profile.isRoot ? CampaignStatusEnum.PendingProcessing : this.profile.status;
  }

  cancelClicked(): void {
    // Reset to the previous values
    this.formGroup.patchValue(pick(this.profile, Object.keys(this.formGroup.getRawValue())));

    if (this.popover) {
      this.popover.close();
    }
  }

  filterDataCatalogSubcategoryOptions(): SimplifiSubcategory[] {
    return this.dataCatalogSubcategories
      ?.filter(sub => {
        if (this.formGroup.value.dataCatalogCategory) {
          return sub.categoryId === this.formGroup.value.dataCatalogCategory;
        } else {
          return false;
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }

  filterDataCatalogAttributeOptions(): SimplifiAttribute[] {
    return this.dataCatalogAttributes
      ?.filter(attr => {
        if (this.formGroup.value.dataCatalogSubcategory) {
          return (
            attr.subcategoryId === this.formGroup.value.dataCatalogSubcategory &&
            attr.categoryId === this.formGroup.value.dataCatalogCategory
          );
        } else {
          return false;
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }

  dataCatalogCategoryChange(category: SimplifiCategory): void {
    this.formGroup.patchValue({ simplifiCategory: category });
    this.formGroup.patchValue({ simplifiSubcategory: null });
    this.formGroup.patchValue({ simplifiAttribute: null });
    this.dataCatalogSubcategoryOptions = this.filterDataCatalogSubcategoryOptions();
  }
  dataCatalogSubcategory(subcategory: SimplifiSubcategory): void {
    this.formGroup.patchValue({ simplifiSubcategory: subcategory });
    this.dataCatalogAttributeOptions = this.filterDataCatalogAttributeOptions();
  }
  dataCatalogAttributeChange(attribute: SimplifiAttribute): void {
    this.formGroup.patchValue({ simplifiAttribute: attribute });
  }

  resetTuneInFields(event: any): void {
    if (!event.checked) {
      this.formGroup.get("lookBackType").reset();
      this.formGroup.get("dwellTimeMinutes").reset(6);
      this.formGroup.get("platforms").reset();
      this.formGroup.get("lookBackStartDate").reset();
      this.formGroup.get("lookBackEndDate").reset();
      this.formGroup.get("dynamicLookBackPeriod").reset();
      this.formGroup.get("dynamicLookBackPeriodType").reset();

      this.formGroup.get("lookBackType").setErrors(null);
      this.formGroup.get("dwellTimeMinutes").setErrors(null);
      this.formGroup.get("platforms").setErrors(null);
      this.formGroup.get("lookBackStartDate").setErrors(null);
      this.formGroup.get("lookBackEndDate").setErrors(null);
      this.formGroup.get("dynamicLookBackPeriod").setErrors(null);
      this.formGroup.get("dynamicLookBackPeriodType").setErrors(null);
    }
  }

  lookbackTypeChange($event: string): void {
    if ($event === "Static") {
      this.formGroup.get("dynamicLookBackPeriod").reset();
      this.formGroup.get("dynamicLookBackPeriod").setErrors(null);
      this.formGroup.get("dynamicLookBackPeriodType").reset();
      this.formGroup.get("dynamicLookBackPeriodType").setErrors(null);
    } else if ($event === "Dynamic") {
      this.formGroup.get("lookBackStartDate").reset();
      this.formGroup.get("lookBackStartDate").setErrors(null);
      this.formGroup.get("lookBackEndDate").reset();
      this.formGroup.get("lookBackEndDate").setErrors(null);
    }
  }

  checkIfTuneInQualifierIsIncluded(rules: any[], tuneInOccurrences: boolean[] = []): boolean {
    for (const rule of rules) {
      const foundRule: boolean = !!rule.rules?.find(r => r.qualifierTypeId === 2) ? true : false;
      if (foundRule || rule.qualifierTypeId === 2) {
        tuneInOccurrences.push(true);
      } else if (rule.rules) {
        this.checkIfTuneInQualifierIsIncluded(rule.rules, tuneInOccurrences);
      }
    }
    return !!tuneInOccurrences.length;
  }

  tuneInFieldRequired(formControlName: string): boolean {
    const formValue: any = this.formGroup.getRawValue();
    switch (formControlName) {
      case "dwellTimeMinutes":
      case "platforms":
      case "lookBackType":
        return formValue.profileType;
      case "lookBackStartDate":
      case "lookBackEndDate":
        return formValue.profileType && formValue.lookBackType === "Static";
      case "dynamicLookBackPeriodType":
      case "dynamicLookBackPeriod":
        return formValue.profileType && formValue.lookBackType === "Dynamic";
      default:
        return false;
    }
  }
  resetCanoeTuneInFields($event: any): void {
    if (!$event.checked) {
      this.formGroup.get("canoeSegmentName").reset();
      this.formGroup.get("canoeSegmentId").reset();
      this.formGroup.get("canoeSegmentName").setErrors(null);
      this.formGroup.get("canoeSegmentId").setErrors(null);
    }
  }

  canoeFieldRequired(): boolean {
    const formValue: any = this.formGroup.getRawValue();
    return formValue.isCanoeTuneIn;
  }
}
